<template>
  <div class="login">
    <div class="login-logo">
      <app-logo size="large"></app-logo>
      <demo-warning></demo-warning>
    </div>
    <div class="login-form">
      <b><i class="fa fa-sign-in"></i> Connexion...</b>
      <b-form @submit="onSubmit">
        <div class="form-fields email-login">
          <b-form-group
            id="email-group"
            label="Adresse email:"
            label-for="email"
            description=""
          >
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              required
              :disabled="step > 1"
              placeholder="prenom.nom@exemple.fr"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="backends-group"
            label="Base de données"
            label-for="backends"
            description="Vous êtes utilisateur de plusieurs bases de données. Sélectionnez celle à utiliser"
            v-if="backends.length > 1"
          >
            <b-form-select
              id="backends"
              v-model="selectedBackend"
              @change="onBackendSelected()"
            >
              <b-form-select-option :value="null">Veuillez sélectionner une base de données</b-form-select-option>
              <b-form-select-option :value="backend" v-for="backend in backends" :key="backend.url">
                {{ backend.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>

        <b-row>
          <b-col>
            <a class="btn btn-secondary" href @click.prevent="onReset()">
              Réinitialiser
            </a>
          </b-col>
          <b-col class="text-right">
            <b-button type="submit" variant="primary" :disabled="!isSubmitActive()">
              Suivant
            </b-button>
          </b-col>
        </b-row>

        <div v-if="errorMessage" class="errors-text">
          {{ errorMessage }}
        </div>

      </b-form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions } from 'vuex'
import AppLogo from '@/components/Layout/AppLogo'
import DemoWarning from '@/components/Layout/DemoWarning'
import { BackendMixin } from '@/mixins/backend'
import { isEmailValid } from '@/utils/check'
import { redirectToUrl } from '@/utils/http'

export default {
  name: 'login',
  components: {
    AppLogo,
    DemoWarning,
  },
  mixins: [BackendMixin],
  data() {
    return {
      selectedBackend: null,
      backends: [],
      form: {
        email: '',
      },
      step: 1,
      errorMessage: '',
    }
  },
  mounted() {
    // if (isAuthenticated()) {
    //   router.push({ name: 'home', })
    // } else {
    //   this.onReset()
    // }
    this.onReset()
  },
  watch: {
    frontendInstance: function(newValue, oldValue) {
      this.onReset()
    },
  },
  computed: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onLogin() {
      this.redirectToFrontEnd()
    },

    onBackendSelected() {
      this.errorMessage = ''
      if (this.selectedBackend) {
        if (this.selectedBackend.frontends.length === 1) {
          this.step = 3
        } else {
          this.errorMessage = 'Veuillez contacter le support millibase.net'
        }
      }
    },

    isSubmitActive() {
      if (this.step === 1) {
        return isEmailValid(this.form.email)
      } else if (this.step === 2) {
        return (this.selectedBackend !== null && this.selectedBackend.url)
      } else if (this.step === 3) {
        return (this.selectedBackend !== null && this.selectedBackend.frontends.length > 0)
      }
    },

    onSubmit(evt) {
      this.errorMessage = ''
      evt.preventDefault()
      if (this.step === 1) {
        // from the email get the list of emails, we can sign-in.
        this.loadBackendsFromEmail()
      } else if (this.step === 3) {
        if (this.selectedBackend && this.selectedBackend.url) {
          this.onLogin()
        }
      }
    },

    loadBackendsFromEmail() {
      if (this.step === 1 && this.form.email) {
        // from the email get the list of backends (instances)
        this.getBackendsFromEmail(this.form.email).then(
          (backends) => {
            this.backends = backends
            if (this.backends.length === 1) {
              this.selectedBackend = this.backends[0]
              this.redirectToFrontEnd()
            } else {
              this.step = 2
            }
          },
          err => {
            this.errorMessage = err.message
          }
        )
      }
    },

    redirectToFrontEnd() {
      this.errorMessage = ''
      if (this.selectedBackend) {
        if (this.selectedBackend.frontends.length === 0) {
          this.errorMessage = 'Veuillez contacter le support Millibase'
        } else if (this.selectedBackend.frontends.length === 1) {
          redirectToUrl(this.selectedBackend.frontends[0].url + '?email=' + this.form.email)
        } else {
          this.errorMessage = 'Veuillez contacter le support Millibase'
        }
      }
    },

    async onReset() {
      this.backends = []
      this.selectedBackend = null
      this.form.email = ''
      this.errorMessage = ''
      this.step = 1
    },
  },
}
</script>

<style lang="less">
  .login {
    margin: 0 25%;
    border: solid 1px transparent;
    position: relative;
  }
  .login-logo {
    padding: 20px;
    position: absolute;
    top: 0;
    z-index: 2;
  }
  .login-form {
    position: absolute;
    border: solid 10px transparent;
    top: 155px;
    width: 100%;
    padding: 40px 20px;
    background: #454851;
    color: #e0e0e0;
    border-radius: 4px;
    z-index: 1;
  }
  .login-form b {
    font-size: 32px;
  }
  ul.home-menu {
    list-style: none;
    color: #fff;
    margin: 0;
    padding: 0;
  }

  ul.home-menu li {
    padding: 10px 0;
    margin: 12px 0;
    display: block;
    border-bottom: solid 1px #c8cfc8;
  }

  ul.home-menu li a{
    padding: 20px;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  ul.home-menu li a:hover {
  }

  .login-logo {
    margin-top: 50px;
    margin-bottom: 20px;
    position: relative;
  }

  .login-logo .version-warning-badge {
    position: absolute;
    top: 0;
    display: inline-block;
    vertical-align: bottom;
    margin: 20px;
  }

  .form-fields {
    border-radius: 4px;
    margin: 20px 0;
    color: #e0e0e0;
  }
  .errors-text {
    background: #9d3434;
    padding: 2px 10px;
    color: #fff;
    margin: 5px 0;
  }
</style>
